<template>
  <div class="submit-class">
    <div class="public-div-border">
      <div class="public-text">标题</div>
      <el-input v-model="form.title" placeholder="请输入标题"></el-input>
    </div>
    <div class="public-div-border">
      <div class="public-text">类型</div>
      <el-select
        v-model="form.sign"
        slot="prepend"
        placeholder="请选择"
        style="width: 100%"
      >
        <el-option
          v-for="(item, index) in typelist"
          :key="'type' + index"
          :label="item.name"
          :value="item.sign"
        ></el-option>
      </el-select>
    </div>

    <div class="public-div-border">
      <div class="public-text">内容</div>
      <el-input
        type="textarea"
        :rows="5"
        v-model="form.context"
        placeholder="请输入内容"
      ></el-input>
    </div>
    <div class="public-div-border">
      <div class="public-text">期待方案</div>
      <el-input
        type="textarea"
        :rows="5"
        v-model="form.expect"
        placeholder="请输入期待方案"
      ></el-input>
    </div>
    <div class="upform">
      <el-button size="mini" @click="upserve" type="success">提交</el-button>
      <el-button size="mini" @click="form = {}">重置</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      typelist: [],
    };
  },
  mounted() {
    this.$axios.get("/appealapi/gettype.php").then((res) => {
      this.typelist = res.data;
    });
  },
  methods: {
    upserve() {
      let form = new FormData();
      for (let i in this.form) {
        form.append(i, this.form[i]);
      }
      form.append('upuser',sessionStorage.getItem('username'))
      this.$axios.post("/appealapi/ups.php", form).then((res) => {
        if (res.data == "success") {
          this.form = {};
          this.$message.success("提交成功！");
        }else{
          this.$message.error(res.data)
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-class {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
</style>